.modal-content {
	background: #eaf0f8;
}

.modal {
	z-index: 1040;
}

/* make modal full size */
.modal-full-size {
	height: 90%;
	width: 90%;
	max-width: none !important;
	max-height: none !important;
}

.modal-full-size .modal-content {
	height: 100%;
}
/***************************/

/*modal lg */
.modal-full-height {
	height: 90%;
	max-height: none !important;
}

.modal-full-height .modal-content {
	height: 100%;
}
/* *********************** */
