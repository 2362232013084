#Loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.5;
	z-index: 9999;
	background: rgb(255, 255, 255);
}

#Loading .spinner-grow {
	width: 10rem;
	height: 10rem;
}
