#Receive,
#RCLeft,
#RCRight,
#RCBottom,
#RCModalReceive form,
#RCModalReceive .container,
#RCModalReceiveDetail .container,
#RCModalReceive .modal-body {
	display: flex;
	flex-direction: column;
}

#RCLeft,
#RCRight,
#RCBottom {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#Receive > .row,
#RCLeft,
#RCRight,
#RCBottom,
#RCLeftFourthRow,
#RCRightFirstRow,
#RCRightSecondRow,
#RCModalReceive form,
#RCModalReceive .container,
#RCModalReceive .container > .full-size {
	flex: 1;
}

#RCBottomFirstRow {
	align-self: center;
}

#RcBottomRow {
	min-height: 300px;
	max-height: 300px;
}

#Receive #ScrollableTable,
#RCModalReceive #ScrollableTable,
#RCModalCancel #ScrollableTable {
	flex: 1;
	overflow: auto;
	border: dashed 1px lightblue;
}

#rc_modal_receive_table_receive > #ScrollableTable,
#rc_modal_receive_table_received > #ScrollableTable {
	max-height: 287px;
}

#rc_modal_cancel_table_receive > #ScrollableTable,
#rc_modal_cancel_table_cancelled > #ScrollableTable {
	max-height: 305px;
}

#RCModalReceiveDetail label {
	text-align: end;
}
