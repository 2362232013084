#HospitelRightActionRow {
	justify-content: center;
}

/* #HospitelModalDoctorOrder #hospital_hospitel_modal_doctor_order_item > #ScrollableTable {
	max-height: 215.52px;
} */

/* #HospitelModalDoctorOrder #hospital_hospitel_modal_doctor_order_pending_item > #ScrollableTable {
	max-height: 327.7px;
} */

#HospitelRight .tab-content:last-child {
	max-height: 170px;
}

#HospitelRight #HospitelRightOrderRow #ScrollableTable td:nth-child(4),
#HospitelRight #HospitelRightOrderRow #ScrollableTable td:nth-child(5) {
	text-align: left;
}
