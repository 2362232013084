#PurchaseOrder,
#POLeft,
#PORight {
	display: flex;
	flex-direction: column;
}

#PurchaseOrder > .row,
#POLeft,
#PORight,
#POLeftFourthRow,
#PORightThirdRow,
#PORightFourthRow {
	flex: 1;
}

#POLeft,
#PORight {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#PORight > fieldset {
	height: unset;
	margin-bottom: 0.3rem !important;
}

#PurchaseOrder #ScrollableTable {
	flex: 1;
	overflow: auto;
	border: dashed 1px lightblue;
}

#PORightSecondRow,
#POEditAction {
	justify-content: center;
}
