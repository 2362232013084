#Inventory {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#InventoryThirdRow {
	margin-bottom: 0.3rem;
	flex: 1;
}

#InventoryFourthRow > div:first-child,
#InventoryThirdRow > div:first-child {
	justify-content: start;
}

#InventoryFourthRow > div:first-child {
	margin-right: 0.3rem;
}

#AddInventory #ScrollableTable {
	margin-bottom: 0.3rem;
	min-height: 19rem;
	max-height: 19rem;
}
