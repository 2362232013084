#ScrollableTable table th,
#ScrollableTable table td {
	/* padding: 0 !important; */
	padding: 0;
	font-size: small;
	white-space: nowrap;
}

#ScrollableTable table tr {
	height: fit-content;
}

#ScrollableTable table {
	margin-bottom: unset;
}

#ScrollableTable td,
#ScrollableTable thead th {
	vertical-align: middle;
	/* text-align: left; */
	text-align: center;
}

/* #ScrollableTable thead tr { */
/* position: relative; */
/* } */

#ScrollableTable [class*="btn"] {
	padding: 0rem 0.3rem !important;
	font-size: 0.7rem;
	vertical-align: text-bottom;
}

#ScrollableTable thead th {
	position: sticky;
	top: 0; /* REQUIRED: */
	resize: horizontal;
	overflow: auto;
	background-color: lightblue;
	border-top: 0;
	text-overflow: ellipsis;
	overflow: hidden;
}

#ScrollableTable td {
	max-width: 68px;
	text-overflow: ellipsis;
	overflow: hidden;
}

#ScrollableTable td > * {
	vertical-align: baseline;
}

.highlight-selected {
	background-color: lightskyblue !important;
	/* background: linear-gradient(90deg, lightskyblue 50%, lightblue 50%); */
}

.selection_cursor {
	cursor: pointer !important;
}
.remove_cursor {
	cursor: context-menu !important;
}

.success {
	background-color: greenyellow !important;
}
