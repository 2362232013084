input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

label {
	font-weight: bold;
	margin-bottom: 0;
}

.remove-padding {
	padding: 0 0 0 0;
}

@media (min-width: 768px) {
	.col-sm-4-half,
	.col-sm-7-half {
		float: left;
	}
	.col-sm-4-half {
		width: 35.6%;
	}
	.col-sm-7-half {
		width: 64.4%;
	}
}

#PurchaseRequest #ScrollableTable {
	height: 100%;
	flex: 1;
	overflow: auto;
	border: dashed 1px lightblue;
}

#PurchaseRequest,
#PRLeft,
#PRRight {
	display: flex;
	flex-direction: column;
}

#PurchaseRequest > .row,
#PRLeft,
#PRLeftFourthRow,
#PRRight,
#PRRightSecondRow {
	flex: 1;
}

#PRRight,
#PRLeft {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#PRLeft .input-group > * {
	margin-right: 0.29rem;
}

#PRLeft .input-group > :last-child {
	margin-right: 0;
}

#PRRightFifthRow {
	min-height: 265px;
	max-height: 265px;
}

#NewPR #pr_modal_npr_table_item > #ScrollableTable,
#NewPR #pr_modal_min_stock_table_item > #ScrollableTable {
	max-height: 277px;
}

#NewPR #pr_modal_npr_table_selected_item > #ScrollableTable,
#NewPR #pr_modal_min_stock_table_selected_item > #ScrollableTable {
	max-height: 313px;
}
