#AdmUserModalNU .container > .row > .col > .row > .col > :first-child,
#AdmUserModalCPW .container > .row > .col > :first-child,
#AdmRoleModalNR .container > .row > .col > .row > .col > :first-child {
	align-self: center;
}

#AdmUserModalNU #ScrollableTable,
#AdmRoleModalNR #ScrollableTable {
	max-height: 323px;
}

#AdmUser > .row:last-child #ScrollableTable,
#AdmRole > .row:last-child #ScrollableTable {
	min-height: 200px;
	max-height: 200px;
}

.signatureCanvas {
	border: 1px solid black;
}
