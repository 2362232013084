#Covid19Lab,
#CLLeft,
#CLRight {
	display: flex;
	flex-direction: column;
}

#Covid19Lab > .row,
#CLLeft,
#CLRight,
#CLLeftPatientRow,
#CLRightLabRow
/* #PORightFourthRow  */ {
	flex: 1;
}

#CLLeft,
#CLRight {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#Covid19Lab #ScrollableTable {
	flex: 1;
	overflow: auto;
	border: dashed 1px lightblue;
}

#CLRight fieldset {
	height: unset;
	margin-bottom: 0.3rem !important;
}

#CLRightActionRow {
	justify-content: center;
}
