/* @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700); */
@import "./css/antd.css";

body,
#root {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

#Main {
	display: flex;
	flex: 1;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

#ScrollableTable {
	height: 100%;
	min-height: 4rem;
	overflow-x:hidden;
	overflow-y:auto;
	border: dashed 1px lightblue;
}

.form-group {
	margin-bottom: 0;
}

.App {
	/*padding-top: 10%;*/
	height: 100%;
	width: 100%;
	position: absolute;
	min-height: 100%;
}

/* Center */
.center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%); /* IE 9 */
	transform: translate(-50%, -50%);
}

.flex-self-center {
	align-self: center;
}

/* vertical cetner */
.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%); /* IE 9 */
	transform: translateY(-50%);
}

/* *********************** */

[class*="form-control"] {
	text-align: center;
}

textarea {
	text-align: start !important;
	flex: 1;
}

textarea[class*="ant-input"]{
	flex: unset;
}

select {
	text-align-last: center;
}
/* **************** */

fieldset {
	height: 100%;
	border: 1px groove #ddd !important;
	padding: 0 0.3rem 0.3rem 0.3rem !important;
	/* margin: 0 0 0.3rem 0 !important; */
	margin: 0 0 0 0 !important;
	-webkit-box-shadow: 0px 0px 0px 0px #000;
	box-shadow: 0px 0px 0px 0px #000;
}

legend {
	font-size: 13px !important;
	font-weight: bold !important;
	text-align: left !important;
	margin-bottom: 0;

	width: inherit; /* Or auto */
	padding: 0 0.3rem;
	border-bottom: none;
}

label,
.self-vertical-center,
.row > [class*="col"] {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

input[variant="warning"] + label,
label[variant="warning"] {
	color: #ffc107;
}

label[variant="red"] {
	color: #dc3545;
}

.margin-top {
	margin-top: 5px;
}

.react-datepicker-popper {
	z-index: 9999 !important;
	/* width: max-content; */
}

.react-datepicker__tab-loop {
	display: inline-block;
}

button > svg {
	margin-left: 1px;
	margin-right: 1px;
	/* display: inline-flex; */
	/* flex-direction: column; */
	/* justify-content: center; */
	vertical-align: text-bottom;
}

.spinner-border,
.spinner-grow {
	position: absolute;
	z-index: 999;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 4rem;
	height: 4rem;
}

hr {
	width: 100%;
}

/* body [class*="container"] > .row, */
body [class*="container"] .row,
body [class*="col"] .row:not(:last-child) {
	margin-bottom: 0.3rem;
}

.form-control:invalid {
	border-color: #dc3545;
}

/* Select all children elements with a attribute type of className */
.react-datepicker__input-container > input[type="text"] {
	text-align: center;
	width: 160px;
	border: 1px solid;
}

.vertical {
	border-left: 1px solid #b7b7b7;
	height: 100%;
	position: absolute;
	left: 50%;
}

.label-info-row > [class*="col"] {
	/* padding-right: 0.3rem; */
	margin-right: 0.3rem;
}
.label-info-row > [class*="col"]:last-child {
	/* padding-right: 0; */
	margin-right: 0;
}

.container {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

/* [class*="col"]:not(:disabled), */
[class*="col"].disabled,
.btn.disabled,
.btn:disabled {
	opacity: 0.4 !important;
}

.background-warning {
	background-color: #ffc107;
}
.background-light-blue {
	background-color: lightskyblue;
}
.background-info {
	background-color: lightblue;
}

/* *************flex container************* */
.full-flex,
.full-flex > .container {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
}

.full-flex .full-size {
	flex: 1;
}

.text-center {
	text-align: center;
}
/* ***************************************** */

.border {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-width: 1px;
	border-style: solid;
	border-color: black !important;
}

/* Tab */
/* .nav-tabs { */
/* border-bottom: unset; */
/* } */

.nav-tabs .nav-link.active {
	border: 0px;
	background-color: #eaf0f8;
	border-bottom: 2px solid #007bff;
	/* margin-bottom: -2px; */
	z-index: 1;
	/* border-color: #9a9a9a #9a9a9a white; */
}

.nav-tabs .nav-link:not(.active) {
	border-color: transparent;
}

.tab-content {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-top: 0.3rem;
	margin-left: 0px;
	margin-right: 0px;
	/* border: 5px inset; */
	/* background-color: white; */
}

.tab-content > div[role="tabpanel"][aria-hidden="false"] {
	display: flex;
	flex-direction: column;
	flex: 1;
}
/*  */

.switch-input:checked:disabled + .switch-label::before {
	background-color: #dedede;
}

label {
	white-space: pre-line;
}

.dropdown-item:hover {
	background-color: #6c757d;
	cursor: pointer;
}

.italic-text {
	font-style: italic;
	font-weight: normal !important;
}

.btn {
	display: inline-flex;
	align-items: center;
	padding: 0.1rem 0.3rem !important;
}

.btn-link:hover,
.btn-link:focus {
	text-decoration: none;
}

.badge {
	white-space: unset;
}

.input-group {
	flex-wrap: nowrap;
}

.modal-md {
	max-width: 650px;
}

/* Ant Design */
.ant-select-selection-item {
	white-space: unset;
}

/* form .ant-select[required] {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
} */

/* form .ant-select {
	border-color: #ced4da !important;
}

.ant-select:hover {
	border-color: #ced4da !important;
} */

/*  */

.ant-descriptions {
	flex: 1;
	display: flex;
}

.ant-descriptions-view {
	flex: 1;
	display: flex;
}

.ant-descriptions-view table {
	flex: 1;
	width: unset;
}

.ant-descriptions tr:last-child > td {
	padding-bottom: 0;
}

.col .ant-radio-group,
.col .ant-checkbox-group {
	display: flex;
}

.col .ant-radio-group .ant-radio-wrapper,
.col .ant-checkbox-group .ant-checkbox-wrapper {
	flex-direction: row;
}


/* ************************************************** */
svg {
	vertical-align: text-bottom;
}