.GInput{
    margin-top: 10px;
    width: 100%;
    height: 100%;
    cursor:text;
    /* font-weight: normal; */
}

.GInput .textfield {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out box-shadow .15s ease-in-out;
}

.GInput .textfield::placeholder{
    color:transparent;
}

.GInput .textfield:disabled+.placeholder,
.GInput .textfield:disabled {
    background: #f5f5f5;
    cursor: not-allowed;
}

.GInput .placeholder {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    color: #A9A9A9;
    margin-left: 10px;
    line-height: 100%;
    top: 50%;
}

.GInput .textfield:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.GInput .textfield:focus~span,
/* .GInput .textfield:not(:focus):valid~span { */
.GInput .textfield:not(:focus):not(:placeholder-shown)~span{
    background: linear-gradient(180deg, #eaf0f8 65%, transparent 35%);
    color:#80bdff;
    top: 0%;
    margin-top: 2px;
}

/* .GInput .textfield:not(:focus):valid~span{ */
.GInput .textfield:not(:focus):not(:placeholder-shown)~span {
    color: inherit;
}

.GInput .placeholder {
    transition: ease-in-out 0.2s all;
    -webkit-transition: ease-in-out 0.2s all;
    -moz-transition: ease-in-out 0.2s all;
    -o-transition: ease-in-out 0.2s all;
}