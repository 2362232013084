#Type .row:first-child {
	justify-content: center;
}

#NewType .container > .row > .col > :first-child {
	align-self: center;
}

#NewType input[type="checkbox"] {
	width: 1.5rem;
	margin: 0;
	padding: 0;
}
