#tf_modal_request_table_item #ScrollableTable,
#tf_modal_request_table_selected_item #ScrollableTable {
	max-height: 253px;
}

#tf_modal_transfer_table_item #ScrollableTable {
	max-height: 335px;
}

#tf_modal_transfer_table_transferred_item #ScrollableTable {
	max-height: 315px;
}
