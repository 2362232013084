#AdmUModalNU .container > .row > .col > :first-child,
#AdmSModalNS .container > .row > .col > :first-child,
#AdmFModalNF .container > .row > .col > :first-child,
#AdmISModalNIS .container > .row > .col > :first-child {
	align-self: center;
}

/* Item */
#AdmIModalNI .col:last-child .row .col > :first-child {
	align-self: center;
}

#AdmIModalNI textarea {
	text-align: center !important;
}

#AdmIModalNI #ScrollableTable {
	max-height: 737px;
}

#ItemConfig #ScrollableTable td {
	vertical-align: middle;
}
/*  */
