#search-text-input {
	border-top: thin solid #e5e5e5;
	border-right: thin solid #e5e5e5;
	border-bottom: 0;
	border-left: thin solid #e5e5e5;
	box-shadow: 0px 1px 1px 1px #e5e5e5;
	float: left;
	height: 27px;
	outline: 0;
	padding: 0.4em 0 0.4em 0.6em;
	width: 183px;
}

#button-holder {
	background-color: #f1f1f1;
	border-top: thin solid #e5e5e5;
	box-shadow: 1px 1px 1px 1px #e5e5e5;
	cursor: pointer;
	float: left;
	height: 27px;
	text-align: center;
	width: 50px;
}

#button-holder img {
	margin: 4px;
	width: 20px;
}

#searchBox {
	padding-left: 0;
	padding-right: 0;
}

#searchBox .row {
	margin-bottom: 0;
}

#searchBox .input-group > * {
	margin-right: 0 !important;
}
