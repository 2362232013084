#InPatientDepartment,
#IPDLeft,
#IPDRight {
	display: flex;
	flex-direction: column;
}

#InPatientDepartment > .row,
#IPDLeft,
#IPDRight,
#IPDLeftPatientRow
/* #CLRightLabRow */ {
	flex: 1;
}

#IPDLeft,
#IPDRight {
	padding-top: 0.3rem;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	border-style: solid;
	border-width: 1px;
}

#InPatientDepartment #ScrollableTable {
	flex: 1;
	overflow: auto;
	border: dashed 1px lightblue;
}

#IPDRight fieldset {
	height: unset;
}

#IPDRightActionRow {
	justify-content: center;
}
