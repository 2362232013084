#Home {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
}

#Home > .container-fluid {
	height: 50%;
}

#Home .row {
	justify-content: center;
}

#Home .col {
	align-items: center;
}
