#hospital_cashier_modal_lab_table_uncharged > #ScrollableTable,
#hospital_cashier_modal_lab_table_pending > #ScrollableTable,
#hospital_cashier_modal_certificate_table_uncharged > #ScrollableTable,
#hospital_cashier_modal_certificate_table_pending > #ScrollableTable {
	max-height: 322px;
}

#hospital_cashier_modal_search_table_patient > #ScrollableTable {
	min-height: 185px;
	max-height: 185px;
}
