#DatePicker {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.react-datepicker-wrapper {
	flex: 1;
}

.react-datepicker__input-container,
.react-datepicker__input-container > input {
	height: 100%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: unset;
	background-color: unset;
	color: unset;
}
